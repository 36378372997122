<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form ref="form" @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Stock Quantity" vid="stock_quantity" rules="required|min_value: 1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="stock_quantity">
                <template v-slot:label>
                  {{ $t('teaGarden.stock_in_quantity') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="stock_quantity"
                  v-model="data.stock_quantity"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
            <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { greenLeadReceiveFromGardenSubmitApi } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
  data () {
    return {
      isGardenAdmin: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        stock_quantity: 0
      },
      has_own_factory: 0,
      status: 1,
      gardenList: [],
      factoryList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getData()
      this.data = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    factoryTypeList () {
      return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Gardens Fatory' : 'বাগানের নিজস্ব কারখানা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Bought Leaf Factory' : 'বটলীফ কারখানা' }
      ]
    },
    insertedByList () {
      return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'System' : 'Stysem' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Direct' : 'সরাসরি' }
      ]
    },
    masterTeaGardenGenInfoList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    masterBoughtLeafFactoryList () {
      return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.factory_type_id === 2)
    }
  },
  watch: {
  },
  methods: {
    finalSave () {
        this.$swal({
          title: this.$t('globalTrans.final_save_msg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.status = 2
            this.saveUpdate()
          }
        })
    },
    async saveUpdate () {
        let result = null
        this.loader = true
        var check = await this.$refs.form.validate()
        if (check) {
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          const loadingState = { loading: false, listReload: false }
          if (this.data.id) {
            result = await RestApi.putData(teaGardenServiceBaseUrl, `${greenLeadReceiveFromGardenSubmitApi}/${this.data.id}`, this.data)
          }
          this.loader = false
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          if (result.success) {
            this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$bvModal.hide('modal-form')
            } else {
            this.$refs.form.setErrors(result.errors)
          }
        }
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
